import React, { useState, createContext, useEffect } from "react";
import { errorMessageAlert, getAlteredListings } from "../functions/functions";

const ListingsContext = createContext();
export default ListingsContext;

export function ListingsProvider(props) {
  const [searchInput, setInput] = useState(null);
  const [listingStatus, setStatus] = useState("sold");
  const [listings, setListings] = useState();
  const [shownListings, setShown] = useState();
  const [filters, setFilters] = useState({
    availability: "any",
    material: "any",
    condition: "any",
  });

  const [filtersChanged, setFiltersChanged] = useState(false);

  useEffect(() => {
    const anyFilterChanged = Object.values(filters).some(
      (filterValue) => filterValue !== "any"
    );
    setFiltersChanged(anyFilterChanged);
  }, [filters]);

  const editFilters = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  function filterListings(newListings) {
    const filteredListings = newListings.filter((listing) => {
      const availabilityFilter = filters.availability;
      const materialFilter = filters.material;
      const conditionFilter = filters.condition;

      const currentDate = new Date();
      const listingStartDate = new Date(listing.pickup_date_start);

      const matchesMaterial =
        materialFilter === "any" || listing.material === materialFilter;
      const matchesCondition =
        conditionFilter === "any" || listing.condition === conditionFilter;

      if (
        availabilityFilter === "any" ||
        (availabilityFilter === "now" && listingStartDate <= currentDate) ||
        (availabilityFilter === "later" && listingStartDate > currentDate)
      ) {
        return matchesMaterial && matchesCondition;
      }

      return false;
    });

    setShown(filteredListings);
  }

  const loadListings = async () => {
    setInput(null);
    try {
      const response = await getAlteredListings(`status=${listingStatus}`);
      setListings(response);
      filterListings(response);
    } catch (error) {
      errorMessageAlert("Failed to load listings");
    }
  };

  const alteredSearch = async (search, status) => {
    let criteria = "";

    if (search || searchInput) {
      criteria += `search=${search || searchInput}`;
      setInput(search || searchInput);
    }

    if (status || listingStatus) {
      if (criteria) {
        criteria += "&";
      }
      criteria += `status=${status || listingStatus}`;
      setStatus(status || listingStatus);
    }

    try {
      const response = await getAlteredListings(criteria);
      setListings(response);
      filterListings(response);
    } catch (error) {
      errorMessageAlert("failed to load listings");
    }
  };

  useEffect(() => {
    if (listings) filterListings(listings);
  }, [filters]);

  useEffect(() => {
    loadListings();
  }, []);

  return (
    <ListingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        shownListings,
        searchInput,
        loadListings,
        alteredSearch,
        filters,
        editFilters,
        filtersChanged,
      }}
    >
      {props.children}
    </ListingsContext.Provider>
  );
}
