import React, { useState, useContext } from "react";
import ListingsContext from "context/ListingsContext";

function Filter(name, list, status) {
  const { editFilters, alteredSearch } = useContext(ListingsContext);
  const [selected, setSelected] = useState(null);

  function handleOptionClick(option) {
    if (status) alteredSearch(null, option.value);
    else editFilters(name, option.value);

    setSelected(option.label);
  }

  return (
    <div
      className={`filter-dropdown-container ${status && "dropdown-wide"}`}
      key={name}
    >
      <button className="top-filter" type="button">
        <span className="rsv-helpfultext left">
          {" "}
          {name}{" "}
          <span className="rsv-header filter-choice">
            {status && !selected ? "Sold" : selected || "Any"}
          </span>{" "}
          {status && <span className="rsv-helpfultext left"> listings </span>}
        </span>
      </button>
      {list.map((option, index) => (
        <>
          <div
            className={`filter-dropdown-line ${index === 0 ? "orange" : ""}`}
          />
          <button
            type="button"
            className="filter-button"
            onClick={() => handleOptionClick(option)}
            key={option.key}
          >
            <span className="rsv-header">{option.label}</span>
          </button>
        </>
      ))}
    </div>
  );
}

export default Filter;
